main {
  background-color: $primary;
  color: $text-color;

  @media(max-width: 767px) {
    margin-top: 80px !important;
  }

  a {
    color: $white;
    text-decoration: underline;

    &:hover {
      color: $white;
    }
  }

  img {
    border-radius: 0;
    border: 2px solid #fff;
  }
}

.alert {
  border-radius: 0;

  .pdf {
    justify-content: center;
    margin-top: 5px;
  }
}

.alert-danger {
  border: 1px solid;
  border-color: #933;
  color: $text-color;
  background-color: #7a2d24;
  text-align: center;
}

.pdf {
  display: flex;
  align-items: center;
  text-decoration: none;

  .fa-file-pdf {
    font-size: 35px;
    width: 1.75em;
  }
}

.card {
  border-color: #933;
  background-color: #7a2d24;
}

.modal-content {
  background-color: $primary;
  color: $text-color;
  border: none;
  border-radius: 25px;

  .modal-header {
    .close {
      color: $text-color;
    }
  }

  .modal-footer {
    .btn {
      color: $text-color;
    }
  }
}

.lightbox-link {
  cursor: pointer;

  .inline-img {
    height: 175px;
    width: auto;
    float:right;
    margin-left: 15px;
  }
}

.lightbox {
  img {
    max-height: 600px;
  }

  .modal-title {
    font-family: 'Courgette';
  }

  .modal-body {
    padding:0;
    justify-content: center;
    display: flex;
  }
}

.inline-link {
  cursor: pointer;
  text-decoration: underline;
}

#osm {
  width: 100%;
  height: 400px;
  margin-top: 25px;
  margin-bottom: 25px;

  img {
    border: none;
  }
}

.form-control {
  &:focus {
    border-color: $text-color;
    box-shadow: 0 0 0 0.2rem $text-color-shadow;
  }
}

.gallery {
  a,
  img {
    max-height: 150px;
  }
}

#panorama {
  width: 100%;
  height: 425px;
  background-color: $grey;
  background-image: url("/images/pattern.jpg");
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin-bottom: 50px;

  .text-box {
    background-color: $primary;
    padding: 15px;
    border-radius: 25px;

    button {
      margin-top: 10px;
    }
  }

  iframe {
    width: 100%;
    height: 425px;
    border: none;
  }
}
