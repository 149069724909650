//sticky footer
html {
  height: 100%;
}

body {
  height: 100%;
  display: flex;
  flex-direction: column;
}

main {
  flex: 1;
}
//sticky foot end

.copyright {
  height: $copyright-height;
  background-color: $primary;
  color: $white;
}

.footer {
  background-color: $bg-beige;
  @media(min-width: 768px) {
    //padding-top: 80px;
  }

  h3 {
    font-family: Courgette;
    color: $grey;
    font-size: 33px;
  }

  h4 {
    font-weight: bold;

    &.summer {
      color: $orange;
    }

    &.winter {
      color: $blue;
    }
  }

  img {
    &.kid{
      position: absolute;
      top: -7px;
      //border-bottom: 2px solid $primary;

      @media(max-width: 767px) {
        position: relative;
        top: 0px;
      }
    }
  }

  .address {
    color: $white;
    background: $primary;
    padding: 6px;
    font-size: 0.8rem;

    a {
      color: inherit;
    }
  }

  .contact {
    color: $white;
    background: $primary;
    padding: 6px;
    font-size: 0.8rem;

    a {
      color: inherit;
    }
  }
}
