body {
  font-family: "Palatino Linotype", "Book Antiqua", Palatino, serif;
}

h1 {
  font-family: Courgette;
  color: $white;
  font-size: 60px;
  line-height: 4.3rem;
  background: -webkit-linear-gradient($white, $beige);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  margin: 20px 0px;

  @media(max-width: 767px) {
    font-size: 49px;
  }
}

h2 {
  color: $white;
  font-size: 16px;
  font-weight: bold;
  border-top: $h2-lines;
  border-bottom: $h2-lines;
  padding: 10px 0px 5px 0;
  margin: 20px 0px;
  letter-spacing: 1.5px;

  &:before {
    content: '';
    display: block;
    position: relative;
    top: -5px;
    border-top: $h2-lines;
  }

  &:after {
    content: '';
    display: block;
    position: relative;
    bottom: 0px;
    border-top: $h2-lines;
  }
}

.modal-title {
  font-family: Courgette;
  color: $white;
  background: -webkit-linear-gradient($white, $beige);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}
