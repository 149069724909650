/* courgette-regular - latin */
@font-face {
  font-family: 'Courgette';
  font-style: normal;
  font-weight: 400;
  src: url('/fonts/Courgette/courgette-v8-latin-regular.eot'); /* IE9 Compat Modes */
  src: local(''),
       url('/fonts/Courgette/courgette-v8-latin-regular.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
       url('/fonts/Courgette/courgette-v8-latin-regular.woff2') format('woff2'), /* Super Modern Browsers */
       url('/fonts/Courgette/courgette-v8-latin-regular.woff') format('woff'), /* Modern Browsers */
       url('/fonts/Courgette/courgette-v8-latin-regular.ttf') format('truetype'), /* Safari, Android, iOS */
       url('/fonts/Courgette/courgette-v8-latin-regular.svg#Courgette') format('svg'); /* Legacy iOS */
}

/* palatino linotype */
@font-face {
  font-family: 'Palatino Linotype';
  font-style: normal;
  font-weight: 400;
  src: local(''),
       url('/fonts/Palatino-Linotype/Palatino.ttf') format('truetype'), /* Safari, Android, iOS */
}

/* palatino */
@font-face {
  font-family: 'Palatino';
  font-style: normal;
  font-weight: 400;
  src: local(''),
       url('/fonts/Palatino/Palatino.ttf') format('truetype'), /* Safari, Android, iOS */
}

/*****************************
 All rights reserved.
 AllFont.ru (c) 2011-2015
 *****************************/
@font-face {
    font-family: 'Book Antiqua';
    font-style: normal;
    font-weight: 400;
    src: local('Book Antiqua'), local('BookAntiqua-Regular'),
        url('/fonts/Book-Antiqua/book-antiqua.woff') format('woff'),
        url('/fonts/Book-Antiqua/book-antiqua.ttf') format('truetype');
}
