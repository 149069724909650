$white: #fff;
$beige: #fcb959;
$text-color: #ffe1c0;
$primary: #6b160c;
$grey: #818181;
$grey-transparent: #818181ad;
$orange: #fc4d00;
$blue: #0088fc;

$primary-shadow: #6b160c40;
$text-color-shadow: #ffe1c040;

$bg-beige: #fff4e6;

$h2-lines: 1px solid #793f3d;

$copyright-height: 50px;
