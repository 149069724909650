.navbar-beige {
  background: $bg-beige;
  background: linear-gradient(180deg, $white 0%, $bg-beige 100%);

  @media(max-width: 767px) {
    border-bottom: 1px solid $primary;
  }

  .nav-link {
    color: $primary;
    font-weight: bold;
    cursor: pointer;
  }
}

.bg-primary {
  background-color: $primary !important;
}

.navbar-brand {
  @media(max-width: 767px) {
    width: 6rem;
  }

  img {
    @media(min-width: 768px) {
      max-height: 80px;
    }
  }
}

.navbar-toggler {
  @media(max-width: 767px) {
    border: 1px solid $primary;
  }

  .fa-bars,
  .fa-times {
    @media(max-width: 767px) {
      color: $primary;
    }
  }
}

#navbarNav {
  display: block;
  position: absolute;
  z-index: 1020;
  overflow-y: scroll;
  height: 100vh;
  width: 100%;
  padding: 10px 15px;
  left: -100%;
  transition: left .5s ease-in-out;
  padding-top: 90px;

  &.show {
    left: 0;
  }
}
