.btn {
  &.btn-default {
    color: $text-color;
    border: 2px solid $text-color;
    border-radius: 50px;
    text-decoration: none;
    font-size: 19px;

    display: inline-block;
    background: linear-gradient(to right, $text-color 50%, transparent 50%);
    background-size: 200% 100%;
    background-position: right bottom;
    transition: all .5s ease-out;

    &:hover {
      color: $primary;
      background-position: left bottom;
    }

    &:focus,
    &.focus,
    &:not(:disabled):not(.disabled):active:focus {
      box-shadow: 0 0 0 0.2rem $text-color-shadow;
    }

    &:not(:disabled):not(.disabled).active,
    &:not(:disabled):not(.disabled):active {
      background-color: $text-color;
      border-color: $text-color;
      color: $primary;
    }
  }

  &.btn-primary {
    color: $primary;
    border: 2px solid $primary;
    border-radius: 50px;
    text-decoration: none;
    font-size: 16px;

    display: inline-block;
    background: linear-gradient(to right, $primary 50%, transparent 50%);
    background-size: 200% 100%;
    background-position: right bottom;
    transition: all .5s ease-out;

    &:hover {
      color: $text-color;
      background-position: left bottom;
    }

    &:focus,
    &.focus,
    &:not(:disabled):not(.disabled):active:focus {
      box-shadow: 0 0 0 0.2rem $primary-shadow;
    }

    &:not(:disabled):not(.disabled).active,
    &:not(:disabled):not(.disabled):active {
      background-color: $primary;
      border-color: $primary;
      color: $text-color;
    }
  }
}

.btn-outline-primary {
  color: $text-color;
  border-color: $text-color;

  &:hover,
  &:focus,
  &:active,
  &:not(:disabled):not(.disabled):active {
    background-color: $text-color;
    border-color: $text-color;
  }

  &:focus,
  &:not(:disabled):not(.disabled):active,
  &:not(:disabled):not(.disabled):active:focus {
    box-shadow: 0 0 0 0.2rem $text-color-shadow;
  }
}
